export default {
  computed: {
    filteredTabsWithRoles() {
      return this.tabs.filter((tab) => {
        if (tab.roles) {
          return tab.roles.some((role) => this.userRoles.includes(role))
        } else {
          return true
        }
      })
    },
  },
}
